<template>
  <div>
    <h1 class="text-2xl font-semibold text-gray-900">
      Bank rekeningen
    </h1>

    <button @click="handleAanmaken">
      + Bank rekening toevoegen
    </button>

    <UILoading v-if="loading" />
    <div v-else class="py-4">
      <UITableResponsive :items="items" :headers="['Bank', 'Geldigheid', 'Accounts']" :suffix-headers="store.getters.hasKantoorPermission ? ['Acties'] : []">
        <template v-slot:colgroup>
          <colgroup>
            <col class="w-0" />
            <col class="w-0" />
            <col class="w-auto" />
            <col v-if="store.getters.hasKantoorPermission" class="w-0" />
          </colgroup>
        </template>
        <template v-slot:item-Bank="{ item }">
          {{ String(item.institution_id).replace(/_+/gi, ' ')}}
          <small class="block">{{ dateTimeStringLocale(item.created)}}</small>
        </template>
        <template v-slot:item-Geldigheid="{ item }">
          <div class="font-bold">
            <span v-if="['EX', 'SU', 'RJ'].includes(item.status)" class="text-red-500">Verlopen</span>
            <span v-else-if="['CR', 'GA', 'SA', 'UA', 'GC'].includes(item.status)" class="text-orange-500">In behandeling</span>
            <span v-else-if="['LN'].includes(item.status)" class="text-green-500">Gelinkt</span>
            <small class="block font-normal">{{ dateTimeStringLocale(getValidityTime(item))}}</small>
          </div>
        </template>
        <template v-slot:item-Accounts="{ item }">
          <span class="font-bold">{{ item.accounts?.length || 0 }} Account(s)</span>
          <small v-if="isSandbox(item)" class="font-bold block">SANDBOX</small>
          <ul class="text-xs flex flex-col gap-1 px-4">
            <li v-for="account in (item?.accounts || [])" :key="account?.id">
              <router-link class="btn info" :to="{ path: `${route.path}/${account?.iban.toUpperCase()}` }">
                {{ formatIban(account?.iban) }}
              </router-link>
            </li>
          </ul>
        </template>
        <template v-slot:item-Acties="{ item }">
          <span>
            <button
              v-if="store.getters.hasKantoorPermission"
              class="btn small error inline-block mt-1"
              @click="handleVerwijderen(item)"
            >
              Verwijderen
            </button>
          </span>
        </template>
      </UITableResponsive>
      <UITablePagination class="mt-2" :count="count" v-model:limit="limit" v-model:offset="offset" />
    </div>
  </div>
</template>

<script setup>
import { defineAsyncComponent } from 'vue'
import { useRoute } from 'vue-router'

import UILoading from '@/components/UI/Loading.vue'
import UITableResponsive from '@/components/UI/Table/Responsive'

import { dateTimeStringLocale } from '@/functions/formatDate'
import { formatIban } from '@/functions/formatIban'
import useApi from '@/hooks/useApi'
import usePaginatedApi from '@/hooks/usePaginatedApi'
import useModal from '@/hooks/useModal'
import { useStore } from 'vuex'

const [openFixedModal] = useModal({ fixed: true })
const UITablePagination = defineAsyncComponent(() => import('@/components/UI/Table/Pagination.vue'))
const api = useApi()
const route = useRoute()
const store = useStore()

const { limit, offset, count, items, getData, loading } = usePaginatedApi('NORDIGEN_REQUISITIONS_LIST')

const getValidityTime = (item) => new Date(item.created).getTime() + (90 * 86400000)
// const isValid = (item) => getValidityTime(item) > Date.now()
const isSandbox = (item) => String(item.institution_id).indexOf('SANDBOX') === 0

const handleVerwijderen = (item) => {
  const callback = () => api('NORDIGEN_REQUISITIONS_VERWIJDEREN', { id: item.id }).then(getData)
  openFixedModal('BEVESTIG_ACTIE', { message: `${item.id} Verwijderen?`, callback })
}

const handleAanmaken = () => {
  openFixedModal('FORM_NORDIGEN_REQUISITION', { callback: getData })
}
</script>
